.ts-icon {
  width: 45% !important;
  height: 100px !important;
  font-size: 100px !important;
}

.ts-stimulus {
  width: 45% !important;
  /*height: 100px !important;*/
  font-size: 100px !important;
  background-color: #ffffff22;
  cursor: pointer;
  position: relative;
}

.ts-inactive {
  cursor: initial;
}

.ts-stimulus:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.ts-stimulus > svg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.correct {
  color: #cecece;
}

.incorrect {
  color: #cecece;
}

.fixation {
  color: #ffffff00;
}

.yellow {
  cursor: pointer;
  color: #fced24;
}

.blue {
  cursor: pointer;
  color: #2323a3;
}

.ts-stimulus-container {
  /* margin: 72px 0 72px 0 !important; */
  padding: 0 60px 0 60px !important;
}

.ts-container {
  padding: 0 24px 0 24px;
  height: calc(100vh - 40px);
}

.ts-empty-stimulus{
  width: 45% !important;
  height: 100% !important;
  position: relative;
}
.ts-empty-stimulus:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}