.about {
  color: red;
}

.study-container {
  margin: 2vh;
}

.view-container {
}

.Text-container {
  padding: 0 24px 0 24px;
}

.languages-container {
  padding: 12px 24px 12px 24px;
}

.MuiDivider-root.matrix-spacer {
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: -24px;
  margin-right: -24px;
}

.matrix-question-container {
  padding: 0px 16px 16px;
}

span.required {
  color: red;
}

.markdown-text {
  font-size: 1rem;
}

.country-select {
  padding-bottom: 16px !important;
}

.matrix-slider .mark {
  color: red;
  font-size: 0.85rem;
  color: #ffffffbb;
}

.matrix-slider {
  padding-top: 20px;
}

.MuiTooltip-tooltip {
  font-size: .85rem !important;
}