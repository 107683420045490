.bart-container {
  height: calc(80vh - 40px)
}

.bubble-container {
  width: 300px;
  height: 500px;
  display: inline-block
}


.bubble {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.bubble:before {
    content: "";
    position: absolute;
    top: 1%;
    left: 5%;
    width: 90%;
    height: 90%;
    border-radius: 100%;
    -webkit-filter: blur(5px);
    filter: blur(5px);
    z-index: 2; }

.bubble:after {
    content: "";
    position: absolute;
    display: none;
    top: 5%;
    left: 10%;
    width: 80%;
    height: 80%;
    border-radius: 100%;
    -webkit-filter: blur(1px);
    filter: blur(1px);
    z-index: 2;
    -webkit-transform: rotateZ(-30deg);
    transform: rotateZ(-30deg); }

.bubble {
    margin: 0px;
    background: radial-gradient(circle at 50% 55%, rgba(255, 140, 140, 0.3), rgba(255, 120, 120, 0.4) 40%, rgba(255, 100, 100, 0.5) 60%, rgba(255, 0, 0, 0.6));
    -webkit-animation: bubble-anim 2s ease-out infinite;
    animation: bubble-anim 2s ease-out infinite; }

.bubble:before {
      -webkit-filter: blur(0);
      filter: blur(0);
      height: 80%;
      width: 40%;
      background: radial-gradient(circle at 130% 130%, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 58%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
      -webkit-transform: translateX(131%) translateY(58%) rotateZ(168deg) rotateX(10deg);
      transform: translateX(131%) translateY(58%) rotateZ(168deg) rotateX(10deg); }

.bubble:after {
      display: block;
      background: radial-gradient(circle at 50% 80%, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 74%, rgba(255,255,255,0.5) 80%, rgba(255,255,255,0.7) 84%, rgba(255, 255, 255, 0) 100%); }

@-webkit-keyframes bubble-anim {
  0% {
    -webkit-transform: scale(1);
  }

  20% {
    -webkit-transform: scaleY(0.95) scaleX(1.05);
  }

  40% {
    -webkit-transform: scaleY(1.02) scaleX(0.98);
  }

  60% {
    -webkit-transform: scaleY(0.98) scaleX(1.02);
  }

  80% {
    -webkit-transform: scaleY(1.02) scaleX(0.98);
  }

  95%, 100% {
    -webkit-transform: scale(1);
  } 
}

@keyframes bubble-anim {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); 
  }

  20% {
    -webkit-transform: scaleY(0.95) scaleX(1.05);
    transform: scaleY(0.95) scaleX(1.05); 
  }

  40% {
    -webkit-transform: scaleY(1.02) scaleX(0.98);
    transform: scaleY(1.02) scaleX(0.98); 
  }

  60% {
    -webkit-transform: scaleY(0.98) scaleX(1.02);
    transform: scaleY(0.98) scaleX(1.02); 
  }

  80% {
    -webkit-transform: scaleY(1.02) scaleX(0.98);
    transform: scaleY(1.02) scaleX(0.98); 
  }

  95%, 100% {
    -webkit-transform: scale(1);
    transform: scale(1); } 
  }
