.stroop-stimulus {
  min-height: 20vh;
}


.stroop-container {
  padding: 0 24px 0 24px;
}

.stroop-board {
  height: calc(100vh - 40px);
}

.stroop-choices {
  padding-bottom: 16px;
}

.stroop-choices .MuiButton-label {
  font-size: 1.4rem;
}