.dictator-container{
  padding: 0 24px 0 24px;
}
.hide{
  display: none !important;
}

#collect-dialog{
  height: 80vh;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded{
  height: 80vh;
}

.max-height{
  height: 100%;
}
